import React from "react";

const ButtonPhone = ({ number, setTelNumber, limitNumber, children }) => {
  const handleNumberLimit = () => {
    if (!limitNumber) {
      if (!isNaN(number)) {
        setTelNumber((prev) => prev + number);
      } else {
        setTelNumber((prev) => prev.slice(0, -1));
      }
    }
  };

  return (
    <div className="w-full flex justify-center">
      <button
        className="text-orange-500 text-shadow rounded-full h-[100px] w-fit relative cursor-pointer  md:m-5  focus:bg-transparent focus-within:bg-transparent focus-visible:bg-transparent visited:bg-transparent active:bg-transparent bg-transparent"
        onClick={handleNumberLimit}
      >
        <span className="absolute left-1/2 -translate-x-1/2 text-4xl md:text-5xl top-1/2 -translate-y-1/2 drop-shadow-lg ">
          {number}
          {children}
        </span>
      </button>
    </div>
  );
};

export default ButtonPhone;
