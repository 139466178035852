import React from "react";
import { useNavigate } from "react-router-dom";
import BackgroundHome from "../../assets/backgrounds/space.png";
import CharactorBoy from "../../assets/backgrounds/spaceboy.png";
import LogoEnterSpace from "../../assets/backgrounds/logo.png";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="h-fit">
      <div className="relative">
        <div className="backgroud-wallpaper h-screen" />
        <div className="absolute top-0 w-full">
          <img
            src={BackgroundHome}
            alt="bg-welcome"
            className="w-full h-screen relative bg-space"
          />

          <img
            src={LogoEnterSpace}
            className="absolute top-5 left-1/2 -translate-x-1/2 w-full w-fit lg:w-fit sm:w-fit"
            alt={`logo`}
          />

          <img
            src={CharactorBoy}
            className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full lg:w-fit sm:w-fit character"
            alt={`character`}
          />

          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full lg:w-fit sm:w-fit ">
            <button
              className="border border-white rounded-full px-20 py-10 button-start"
              onClick={() => navigate("/detect")}>
              <p className="text-lg lg:text-2xl text-center italic text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] ">
                สัมผัสหน้าจอเพื่อเริ่ม
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
