const hideDigits = (number) => {
  if (number.length === 5) {
    return "X" + number.substring(1);
  } else if (number.length === 6) {
    return "XX" + number.substring(2);
  } else if (number.length === 7) {
    return "XXX" + number.substring(3);
  } else if (number.length === 8) {
    return "XXXX" + number.substring(4);
  } else if (number.length === 9) {
    return "XXXXX" + number.substring(5);
  } else if (number.length >= 10) {
    return "XXXXXX" + number.substring(6);
  }
  return number;
};

const phoneNumberFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^X0-9]/g, "");
  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\w{3})(\w{1})/, "$1-$2");
  if (number.length < 11)
    return number.replace(/(\w{3})(\w{3})(\w{1})/, "$1-$2-$3");
  return number.replace(/(\w{3})(\w{3})(\w{4})/, "$1-$2-$3");
};

export { hideDigits, phoneNumberFormat };
