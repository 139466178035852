import React, { useState, useRef, useEffect } from "react";
import ButtonPhone from "../../components/ButtonPhone";
import { ArrowLeft, Home } from "react-feather";
import { FaCamera } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Webcam from "react-webcam";

import { hideDigits, phoneNumberFormat } from "../../utility/phone";
import { getUserByName, checkPhoneNumber } from "../../services/User";
import { register, updateImage } from "../../services/Register";
import { croppedFace, getCurrentData } from "../../services/FaceRecognize";

const Register = ({ setUsername, hasData, setInitialData }) => {
  const navigate = useNavigate();
  const webcamRef = useRef();
  const canvasRef = useRef();
  const [tel, setTel] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [disable, setDisable] = useState(false); // When phoneNumber have a 10 digit and
  const [Sequent, setSequent] = useState(1); // 1: checkTel 2: RegisterForm 3: CaptureImage to confirm
  const [captured, setCaptured] = useState(""); //Full image
  const [faceImage, setFaceImage] = useState(""); //Face only

  const [initData, setInitData] = useState([]); //if has a data
  const [isActive, setIsActive] = useState(false); // close camera || opened camera
  const [isSummiting, setIsSummitting] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    imagePath: null,
    imageProfile: null,
  });

  //
  useEffect(() => {
    const phoneNumberRegex = /^(?:06|08|09)\d{8}$/;

    if (!phoneNumberRegex.test(tel)) {
      setDisable(true);
      setIsActive(false);
    } else {
      setDisable(false);
      setIsActive(true);
    }
    if (tel.length === 10) {
      setDisable(false);
    }

    const hiddenPhoneNumber = hideDigits(tel);

    setPhoneNumber(phoneNumberFormat(hiddenPhoneNumber));
  }, [tel, disable]);

  //FormData Register
  const fd = new FormData();
  fd.append("username", formData.username);
  fd.append("email", formData.email);
  fd.append(
    "phoneNumber",
    formData.phoneNumber === "" ? tel : formData.phoneNumber
  );
  fd.append("imagePath", formData.imagePath);
  fd.append("imageProfile", formData.imageProfile);

  //FormData updateImage
  const formImage = new FormData();
  formImage.append("username", initData?.username);
  formImage.append("imagePath", formData?.imagePath);
  formImage.append("imageProfile", formData?.imageProfile);

  const handleSubmit = async (e) => {
    setIsSummitting(true);
    e?.preventDefault();
    switch (Sequent) {
      case 1:
        const data = await checkPhoneNumber({ phoneNumber: tel });
        if (data?.user) {
          setInitData(data?.user);
          setIsSummitting(false);
          setSequent(2);
        } else {
          setSequent(2);
          setIsSummitting(false);
        }
        break;
      case 2:
        if (initData?.length === 0) {
          console.log("fd imagePath", formData.imagePath);
          if (formData.imagePath) {
            if (fd.get("username") && fd.get("email")) {
              const newClient = await register(fd);
              setIsSummitting(false);
              if (newClient?.status) {
                const data = await getUserByName({
                  username: formData.username,
                });
                if (data?.status) {
                  setIsSummitting(false);
                  const prepareData = await getCurrentData({
                    username: formData.username,
                  });
                  if (data?.user && prepareData) {
                    setInitialData(data?.user);
                    getCurrentData({ username: formData.username });
                    navigate("/member");
                  }
                }
              } else {
                Swal.fire({
                  title: "ชื่อผู้ใช้ซ้ำหรืออีเมลซ้ำ",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 1000,
                });
                setIsSummitting(false);
              }
            } else {
              Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
              setIsSummitting(false);
            }
          } else {
            Swal.fire({
              title: "กรุณาถ่ายรูป",
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
            });
          }
          setIsSummitting(false);
        } else {
          setUsername(formImage.get("username"));
          if (formData.imagePath && formData.imageProfile) {
            const success = await updateImage(initData?._id, formImage);
            if (success) {
              getCurrentData({ username: formImage.get("username") });
              navigate("/member");
              setIsSummitting(false);
            }
          } else {
            Swal.fire({
              title: "กรุณาถ่ายรูป",
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
            });
            setIsSummitting(false);
          }
        }
        break;
    }
  };

  const capturedImage = async () => {
    console.log("captured");
    const fullImage = webcamRef.current.getScreenshot();
    const blobFullImage = await fetch(fullImage).then((res) => res.blob());
    const formImage = new FormData();
    formImage.append("imageProfile", blobFullImage);
    const crop_face = await croppedFace(formImage);
    if (crop_face == null) {
      setCaptured("");
      setFaceImage("");
      Swal.fire({
        title: "Something went wrong",
        text: "Face not found or Near Camera",
        icon: "error",
      });
    } else {
      setCaptured(fullImage);
      setFaceImage(crop_face);
      const blobCropFace = await fetch(
        `data:image/jpeg;base64,${crop_face}`
      ).then((res) => res.blob());
      console.log("FullImage", blobFullImage);
      console.log("CropFace: ", blobCropFace);
      setFormData({
        ...formData,
        imagePath: blobCropFace,
        imageProfile: blobFullImage,
      });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="overflow-y-clip">
      {Sequent === 1 && (
        <div className="h-screen flex flex-col justify-center">
          <Link to={"/"}>
            <Home
              color="white"
              size={60}
              className="mx-auto my-5"
              height="48"
            />
          </Link>

          <p
            className={`text-white ${
              phoneNumber ? "text-2xl md:text-5xl" : "text-2xl md:text-5xl"
            } text-center tracking-widest`}
          >
            {!phoneNumber ? "ระบุเบอร์โทรศัพท์" : phoneNumber}
          </p>

          <div className="p-5">
            <div className="grid grid-cols-3">
              <ButtonPhone
                number={1}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={2}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={3}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={4}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={5}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={6}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={7}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={8}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <ButtonPhone
                number={9}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <div></div>
              <ButtonPhone
                number={0}
                setTelNumber={setTel}
                limitNumber={!disable}
              />
              <div className={`${!phoneNumber && "hidden"}`}>
                <ButtonPhone setTelNumber={setTel}>
                  <div className="arrow-pointer flex justify-center items-center">
                    <span className="text-2xl text-white">X</span>
                  </div>
                </ButtonPhone>
              </div>
            </div>

            {isActive && (
              <button
                className="bg-[#EB7734] rounded-full w-full p-2 text-2xl text-white "
                onClick={handleSubmit}
                disabled={isSummiting}
              >
                {!isSummiting ? "ยืนยัน" : "กำลังประมวลผล"}
              </button>
            )}
            {!isActive && !disable && (
              <button
                className="bg-[#EB7734] rounded-full w-full p-2 my-5 text-5xl text-white"
                onClick={() => setTel("")}
              >
                ล้างทั้งหมด
              </button>
            )}
          </div>
        </div>
      )}

      {Sequent === 2 && (
        <div>
          <div className="text-white font-bold text-3xl lg:text-5xl border-b-2 border-[#EB7734] w-1/2 p-3 flex lg:justify-evenly items-center">
            <div
              className="bg-white w-fit p-2 lg:p-5 rounded-full cursor-pointer text-black mr-5
              "
              onClick={() => {
                setSequent(Sequent - 1);
                setCaptured("");
                setInitData([]);
              }}
            >
              <ArrowLeft />
            </div>
            REGISTER
          </div>
          <div
            className=" w-48 h-48 lg:w-72 lg:h-72 rounded-full bg-white relative cursor-pointer overflow-hidden mx-auto my-1"
            onClick={() => setSequent(3)}
          >
            {!captured ? (
              <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                <FaCamera size={48} className="mx-auto" />
                <p className="lg:text-2xl text-nowrap font-bold text-xs">
                  ถ่ายรูป
                </p>
              </div>
            ) : (
              <div className="w-full h-full">
                <img
                  src={captured}
                  className="shadow-2xl filter brightness-125 w-full h-full object-cover"
                />
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col w-full relative my-1">
              <label className="text-orange-500 text-shadow text-3xl lg:text-6xl">
                เบอร์โทรศัพท์
              </label>
              <input
                name="phoneNumber"
                type="tel"
                pattern="(06|08|09)\d{8}"
                placeholder="Phone"
                className="border-b border-b-[#EB7734] w-full text-2xl lg:text-5xl focus:outline-none bg-transparent text-white border-image"
                defaultValue={initData.length > 0 ? initData?.phoneNumber : tel}
                onChange={handleOnChange}
                size={10}
                required
                readOnly
              />
            </div>
            <div className="flex flex-col w-full relative  my-1">
              <label className="text-orange-500 text-shadow text-3xl lg:text-6xl">
                ชื่อผู้เล่น
              </label>
              <input
                name="username"
                placeholder="User"
                className="border-b border-b-[#EB7734] from-orange-600 p-2.5 w-full text-2xl lg:text-5xl focus:outline-none bg-transparent text-white"
                defaultValue={initData?.username || formData.username}
                onChange={handleOnChange}
                required
                readOnly={initData.username ? true : false}
                autoComplete="off"
              />
            </div>
            <div className="flex flex-col w-full relative  my-1">
              <label className="text-orange-500 text-shadow text-3xl lg:text-6xl">
                อีเมลล์
              </label>
              <input
                name="email"
                placeholder="Email"
                type="email"
                className="border-b border-b-[#EB7734] p-2.5 w-full text-2xl lg:text-5xl focus:outline-none bg-transparent text-white overflow-clip"
                defaultValue={initData?.email || formData.email}
                onChange={handleOnChange}
                required
                autoComplete="off"
                readOnly={initData.email ? true : false}
              />
            </div>
            <button
              className="bg-[#EB7734] text-white rounded-full w-full p-2 lg:p-5 text-xl lg:text-3xl"
              disabled={isSummiting}
            >
              {isSummiting ? "กำลังประมวลผล" : "ยืนยัน"}
            </button>
          </form>
        </div>
      )}
      {Sequent === 3 && (
        <div className="relative">
          {!captured && (
            <div>
              <Webcam
                ref={webcamRef}
                mirrored={true}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                className="w-full relative h-screen object-cover"
              />
              <div className="absolute bottom-5 left-1/2 -translate-x-1/2">
                <div className="border border-white w-fit rounded-full p-2">
                  <button
                    onClick={() => {
                      capturedImage();
                    }}
                    className="text-white bg-[#F7B84B] p-5 rounded-full w-24 h-24"
                  />
                </div>
              </div>
            </div>
          )}
          {captured && (
            <div className="h-screen w-full relative">
              <img
                src={captured}
                alt="fullImage"
                className="h-screen w-full object-cover"
              />

              <div className="absolute left-1/2 -translate-x-1/2 bottom-10">
                <div className="flex gap-5">
                  <div
                    className="text-[#EE803A] bg-white px-5 py-3 rounded-full w-[150px] text-center hover:cursor-pointer"
                    onClick={() => setCaptured("")}
                  >
                    ลองอีกครั้ง
                  </div>
                  <div
                    className="text-white bg-[#EE803A] px-5 py-3 rounded-full w-[150px] text-center hover:cursor-pointer"
                    onClick={() => setSequent(2)}
                  >
                    ตกลง
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Register;
