import * as faceapi from "face-api.js";
import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { croppedFace, postFaceRecogize } from "../../services/FaceRecognize";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { uploadImage } from "../../services/Register";

import { Spinner, Spinner2 } from "./Components/Spinner";

const DetectPage = ({ setUsername }) => {
  const [status, setStatus] = useState("closed");
  const [outlier, setOutlier] = useState(null);
  const [message, setMessage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [displayOpen, setDisplayOpen] = useState(true);
  const webcamRef = useRef(null);
  const detection = useRef();
  const counter = useRef(0);
  let sentEndpoint = useRef(false);

  const constraints = {
    facingMode: "user",
    width: 320,
    height: 480,
  };

  useEffect(() => {
    setInterval(() => {
      setDisplayOpen(false);
    }, 1000);
    setStatus("opened");
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearInterval(detection.current);
    };
  }, [status]);

  const handleStreamVideo = async (e) => {
    try {
      if (status === "opened") {
        // Load All model
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
        ])
          .then(() => {
            detection.current = setInterval(async () => {
              if (!sentEndpoint.current) {
                if (counter.current < 30) {
                  if (webcamRef.current !== null) {
                    const faces = await faceapi
                      .detectAllFaces(
                        webcamRef.current?.video,
                        new faceapi.TinyFaceDetectorOptions()
                      )
                      .withFaceLandmarks();
                    const resizedDetections = faceapi.resizeResults(faces, {
                      width: 768,
                      height: 1024,
                    });

                    let faceInArea = [];
                    setOutlier("#FF0000");

                    for (let i = 0; i < faces.length; i++) {
                      if (faces[i].detection.box.x) {
                        faceInArea.push(i);
                      }
                    }

                    if (faceInArea.length === 0) {
                      counter.current = 0;
                      setMessage(0);
                    }

                    let largestArea = 0;
                    let largestIndex;
                    for (let k = 0; k < faceInArea.length; k++) {
                      let area =
                        resizedDetections[faceInArea[k]].detection.box.width *
                        resizedDetections[faceInArea[k]].detection.box.height;
                      if (area > largestArea) {
                        largestArea = area;
                        largestIndex = faceInArea[k];
                      }
                    }

                    resizedDetections.forEach((detection, index) => {
                      if (largestIndex === index) {
                        setOutlier("#00ff00");
                        counter.current++;
                        setMessage(Math.round(3 - counter.current / 10));
                      }
                    });
                  }
                } else {
                  sentEndpoint.current = true;
                  const imageSrc = webcamRef.current.getScreenshot();

                  const blobFullImage = await fetch(imageSrc)
                    .then((res) => {
                      return res.blob();
                    })
                    .catch((error) => {
                      console.log(error);
                    });

                  const ImageFaceRecog = new FormData();
                  ImageFaceRecog.append("image", blobFullImage);

                  const user = await postFaceRecogize(ImageFaceRecog)
                    .then((res) => {
                      return res;
                    })
                    .catch((error) => {
                      // console.log("Python Error : ", error);
                      navigate("/register");
                    });

                  if (user) {
                    counter.current = 0;
                    setMessage(0);
                    if (
                      user?.name[0] !== undefined &&
                      user?.name[0] !== "Unknown" &&
                      user?.name[0] !== "Face Not found on Server"
                    ) {
                      setUsername(user.name[0]);
                      Swal.fire({
                        title: `ยินต้อนรับสู่ Enter Space`,
                        text: `คุณคือ ${user?.name[0]} อยากเข้าร่วมกับเราหรือไม่`,
                        imageUrl: imageSrc,
                        showDenyButton: true,
                        confirmButtonText: "ตกลง",
                        confirmButtonColor: "#00FF00",
                        denyButtonText: "ไม่เข้าร่วม",
                        reverseButtons: true,
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          const ImageCropFace = new FormData();
                          ImageCropFace.append("imageProfile", blobFullImage);
                          const crop_face = await croppedFace(ImageCropFace);
                          const blobCropFace = await fetch(
                            `data:image/jpeg;base64,${crop_face}`
                          ).then((res) => res.blob());

                          const formUploadImage = new FormData();
                          formUploadImage.append("username", user?.name[0]);
                          formUploadImage.append("imagePath", blobCropFace);

                          await uploadImage(formUploadImage);
                          navigate("/member");
                          sentEndpoint.current = false;
                        } else {
                          sentEndpoint.current = false;
                        }
                      });
                    } else if (
                      user?.name[0] === "Face Not found on Server" ||
                      user?.name[0] === undefined
                    ) {
                      Swal.fire({
                        title: "ไม่สามารถจับใบหน้าได้",
                        imageUrl: imageSrc,
                        confirmButtonText: "ตกลง",
                        confirmButtonColor: "#00FF00",
                        reverseButtons: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          sentEndpoint.current = false;
                        }
                      });
                    } else {
                      Swal.fire({
                        title: "ไม่สามารถระบุตัวตนได้",
                        text: "กรุณาเข้าร่วมกับเรา",
                        imageUrl: imageSrc,
                        showDenyButton: true,
                        confirmButtonText: "ตกลง",
                        confirmButtonColor: "#00FF00",
                        denyButtonText: "ไม่เข้าร่วม",
                        reverseButtons: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/register");
                          sentEndpoint.current = false;
                        } else {
                          sentEndpoint.current = false;
                        }
                      });
                    }
                  }
                }
              }
              return false;
            }, 50);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.error("Webcam reference or video element is not valid.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const outlierTime = 15000;

  useEffect(() => {
    let timer;

    const handleColorChange = () => {
      clearTimeout(timer);
      if (outlier === "#ff0000") {
        timer = setTimeout(() => {
          window.location.href = "/";
        }, outlierTime);
      }
    };

    handleColorChange();

    return () => clearTimeout(timer);
  }, [outlier]);

  return (
    <div className="w-full mx-auto relative">
      {displayOpen && <Spinner />}

      {sentEndpoint.current && <Spinner2 />}
      <div
        className={`relative w-full h-screen ${
          sentEndpoint.current ? "blur" : ""
        }`}>
        <Webcam
          ref={webcamRef}
          width={1280}
          height={720}
          mirrored={true}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          videoConstraints={constraints}
          onUserMedia={(e) => {
            handleStreamVideo(e);
            setLoading(true);
          }}
          className="w-full relative h-full object-cover"
        />
        {loading && (
          <div
            className={`absolute top-1/2 -translate-y-1/2 mb-[20px] left-1/2 -translate-x-1/2 border-8  border-dashed border-light-gray rounded-[50%] w-full md:w-1/2 h-3/4 md:h-full shadow-[0_0_20px_1000px_rgb(0_0_0/80%)] face-scan`}
            style={{ borderColor: outlier }}>
            {message > 0 && (
              <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-50 text-8xl opacity-20">
                {message}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetectPage;
