import axios from "axios";

export const postFaceRecogize = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_PY}/face_recog_full_image`, data)
    .then((res) => res.data);
};

export const croppedFace = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_PY}/smart-crop`, data)
    .then((res) => res.data);
};

export const getCurrentData = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_PY}/currentDB`, data)
    .then((res) => res.data);
};

export const getPy = async () => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_PY}/get_py`)
    .then((res) => res.data);
};
