import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import { getUserByName } from "./services/User";
import "./styles/index.css";

import HomePage from "./pages/HomePage";
import FaceDetectPage from "./pages/DetectPage";
import Register from "./pages/Register";
import MemberPage from "./pages/MemberPage";

function App() {
  const [username, setUsername] = useState("");
  const [initialData, setInitialData] = useState([]);
  const setName = async (username) => {
    return setUsername(username);
  };
  const getUser = async () => {
    const result = await getUserByName({ username: username });
    setInitialData(result?.user);
  };

  useEffect(() => {
    if (username !== "" || undefined || null) {
      getUser();
    }
  }, [username]);

  return (
    <div>
      <Routes>
        <Route
          path="/detect"
          element={<FaceDetectPage setUsername={setName} />}
        />
        <Route path="/" element={<HomePage />} />
        <Route
          path="/register"
          element={
            <Register
              setUsername={setName}
              hasData={initialData}
              setInitialData={setInitialData}
            />
          }
        />
        <Route
          path="/member"
          element={<MemberPage initialData={initialData ? initialData : ""} />}
        />
      </Routes>
    </div>
  );
}

export default App;
