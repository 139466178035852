import React from "react";

export const Spinner = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center bg-black absolute z-[999] ">
      <svg
        version="1.1"
        id="L9"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
        className=" animate-spin w-1/2 ">
        <path
          fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path>
      </svg>
    </div>
  );
};

export const Spinner2 = () => {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2  -translate-y-1/2 z-10 ">
      <svg
        version="1.1"
        id="L9"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
        className="animate-spin  w-full">
        <path
          fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path>
      </svg>
    </div>
  );
};
