// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-box {
  text-align: center;
}

.backgroud-wallpaper {
  /* background-image: url("/src/assets/images/enter_space.jpg"); */
  filter: blur(2rem);
  background-repeat: round;
  background-size: auto;
}

.bg-space {
  position: relative;
  background-repeat: no-repeat;
}

.button-start {
  animation: zoom infinite 1s;
  margin-bottom: 40vh;
}

.character {
  animation: bounce 2s infinite;
}

.button-start:hover {
  transform: scale(1.2);
  border-color: #ff9000;
  animation: zoom 1s;
}

.face-scan {
  width: 700px;
  height: 700px;
}

.top-10 {
  top: 10rem;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/custom/welcome.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iEAAiE;EACjE,kBAAkB;EAClB,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".welcome-box {\n  text-align: center;\n}\n\n.backgroud-wallpaper {\n  /* background-image: url(\"/src/assets/images/enter_space.jpg\"); */\n  filter: blur(2rem);\n  background-repeat: round;\n  background-size: auto;\n}\n\n.bg-space {\n  position: relative;\n  background-repeat: no-repeat;\n}\n\n.button-start {\n  animation: zoom infinite 1s;\n  margin-bottom: 40vh;\n}\n\n.character {\n  animation: bounce 2s infinite;\n}\n\n.button-start:hover {\n  transform: scale(1.2);\n  border-color: #ff9000;\n  animation: zoom 1s;\n}\n\n.face-scan {\n  width: 700px;\n  height: 700px;\n}\n\n.top-10 {\n  top: 10rem;\n}\n\n@keyframes zoom {\n  0% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.2);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
