import axios from "axios";

export const getUserByName = (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_NODE}/user`, data)
    .then((res) => res.data);
};

export const checkPhoneNumber = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_NODE}/user/phonenumber`, data)
    .then((res) => res.data);
};
