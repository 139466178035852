import axios from "axios";
export const register = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_NODE}/register`, data)
    .then((res) => res.data);
};

export const updateImage = async (id, data) => {
  return axios
    .put(
      `${process.env.REACT_APP_SERVER_NODE}/register/upload-image/${id}`,
      data
    )
    .then((res) => res.data);
};

export const uploadImage = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_NODE}/register/upload-image`, data)
    .then((res) => res.data);
};
